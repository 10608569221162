'use client';

import { readFormat, writeFormat } from '@hx/commons/constants';
import { Button, Calendar, Input, Popover, PopoverContent, PopoverTrigger } from '@hx/ui/inputs';
import { cn } from "../../lib";
import { CalendarIcon } from '@radix-ui/react-icons';
import { es } from 'date-fns/locale';
import dayjs from 'dayjs';
import * as React from 'react';
function DatePicker({
  required,
  handleChangePicker,
  dateMin,
  dateMax,
  defaultDate
}: {
  required: boolean;
  handleChangePicker: (event: any) => void;
  dateMin?: string;
  dateMax?: string;
  defaultDate?: string;
}) {
  const [date, setDate] = React.useState<Date>();
  React.useEffect(() => {
    if (date) {
      handleChangePicker(dayjs(date).format(writeFormat));
    }
  }, [date]);
  React.useEffect(() => {
    if (!defaultDate) return;
    setDate(dayjs(defaultDate).toDate());
  }, []);
  const min = dateMin ? dayjs(dateMin).toDate() : dayjs().subtract(100, 'years').toDate();
  const max = dateMax ? dayjs(dateMax).toDate() : dayjs().add(100, 'years').toDate();
  return <Popover data-sentry-element="Popover" data-sentry-component="DatePicker" data-sentry-source-file="date-picker.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="date-picker.tsx">
        <Button variant={'outline'} className={cn('w-full justify-start text-left font-normal', !date && 'text-muted-foreground')} data-sentry-element="Button" data-sentry-source-file="date-picker.tsx">
          <CalendarIcon className="mr-2 h-4 w-4" data-sentry-element="CalendarIcon" data-sentry-source-file="date-picker.tsx" />
          <Input className="border-0 focus:border-0 focus-visible:ring-0 cursor-pointer shadow-none" type="text" placeholder="Selecciona una fecha" required={required} value={dayjs(date).format(readFormat)} onChange={() => {}} data-sentry-element="Input" data-sentry-source-file="date-picker.tsx" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="flex w-auto flex-col mx-auto space-y-2 p-2" data-sentry-element="PopoverContent" data-sentry-source-file="date-picker.tsx">
        <div className="rounded-md ">
          {/* TODO: review this change captionLayout="dropdown" */}
          <Calendar mode="single" captionLayout="dropdown-buttons" selected={date} onSelect={setDate} fromYear={1900} toYear={2050} locale={es} disabled={date => date < min || date > max} data-sentry-element="Calendar" data-sentry-source-file="date-picker.tsx" />
        </div>
      </PopoverContent>
    </Popover>;
}
export { DatePicker };