export enum  ActivityStatus{
  OPEN= 'open',
  IN_PROGRESS='in_progress',
  CLOSED= 'closed',
}

export enum ActivityType{
  CALL= 'call',
  CLOSE_THE_LOOP= 'ctl',
  TASK= 'task',
}