'use client';

import { buttonVariants } from '@hx/ui/inputs';
import * as Sentry from '@sentry/nextjs';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';
export default function GlobalError({
  error,
  showBack = false
}: {
  error: any & {
    digest?: string;
  };
  showBack?: boolean;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <div className="flex flex-col items-center justify-center h-screen">
          <Image src="/img/compass_logo.svg" alt="CompassCx Logo" width={150} height={100} data-sentry-element="Image" data-sentry-source-file="global-error.tsx" />
          <p className="m-2">{error.publicMessage || 'Ha ocurrido un error, intenta de nuevo.'}</p>
          {showBack && <Link href="/" className={buttonVariants({
          variant: 'outline'
        })}>
              Volver
            </Link>}
        </div>
      </body>
    </html>;
}