export const SUB_QUESTION = 'subtype_question';
export const SUB_MISC = 'subtype_miscellaneous';
export const SUB_THIRD = 'subtype_thirparty';

export const MISC_INFO = 'miscellaneous_info';
export const MISC_IMAGE = 'miscellaneous_image';
export const MISC_INPUT = 'miscellaneous_input';
export const THIRD_TWILLIO = 'thirdparty_twillio';
export const QUESTION_OPEN = 'question_open';
export const QUESTION_SCALE = 'question_scale';
export const QUESTION_SELECT = 'question_select';
export const QUESTION_NESTED_SELECT = 'question_nested_select';
