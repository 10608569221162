'use client';

import { CalendarIcon } from '@radix-ui/react-icons';
import { es } from 'date-fns/locale';
import { Button, Calendar, Input, Popover, PopoverContent, PopoverTrigger } from '@hx/ui/inputs';
import { cn } from '../../lib';
import dayjs from 'dayjs';
import { readFormat, readMidFormat, writeFormat } from '@hx/commons/constants';
import { DateRange } from 'react-day-picker';
import { useState } from 'react';
import 'dayjs/locale/es';
dayjs.locale('es');
function DatePickerWithRange({
  range,
  onSelect
}: {
  required: boolean;
  dateMin?: string;
  dateMax?: string;
  range?: DateRange;
  onSelect: (range: DateRange | undefined) => void;
}) {
  return <div className={cn('grid gap-2', '')} data-sentry-component="DatePickerWithRange" data-sentry-source-file="date-range-picker.tsx">
      <Popover data-sentry-element="Popover" data-sentry-source-file="date-range-picker.tsx">
        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="date-range-picker.tsx">
          <Button variant={'outline'} className={cn('w-full justify-start text-left font-normal', !range && 'text-muted-foreground')} data-sentry-element="Button" data-sentry-source-file="date-range-picker.tsx">
            <CalendarIcon className="mr-2 h-4 w-4" data-sentry-element="CalendarIcon" data-sentry-source-file="date-range-picker.tsx" />
            {range?.from ? <>
                {dayjs(range.from).format(readMidFormat)} {range.to && `- ${dayjs(range.to).format(readMidFormat)}`}
              </> : <span>Selecciona una fecha</span>}
            
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="flex w-auto flex-col mx-auto space-y-2 p-2 me-8" data-sentry-element="PopoverContent" data-sentry-source-file="date-range-picker.tsx">
          <div className="rounded-md ">
            <Calendar initialFocus mode="range" defaultMonth={range?.from} selected={range} onSelect={onSelect} numberOfMonths={2} fromYear={1900} toYear={2050} locale={es} data-sentry-element="Calendar" data-sentry-source-file="date-range-picker.tsx" />
          </div>
        </PopoverContent>
      </Popover>
    </div>;
}
export { DatePickerWithRange };